import React from "react";
import "./services.css";
import { FaCheckSquare } from "react-icons/fa";

const Services = () => {
  return (
    <section id="services">
      <h5>What I Offer</h5>
      <h2>Services</h2>
      <div className="container services_container">
        <article className="services">
          <div className="service_head">
            <h3>Front-end Creation</h3>
          </div>
          <ul className="service_list">
              <li>
        <FaCheckSquare className="service_list-icon" />
        <p>I specialize in building engaging front-end websites and desktop applications.</p>
      </li>
      <li>
        <FaCheckSquare className="service_list-icon" />
        <p>I excel in optimizing applications for maximum speed and performance.</p>
      </li>
      <li>
        <FaCheckSquare className="service_list-icon" />
        <p>I work collaboratively with back-end developers and web designers to enhance user experience and usability.</p>
      </li>
          </ul>
        </article>
        <article className="services">
          <div className="service_head">
            <h3>Web Development</h3>
          </div>
          <ul className="service_list">
              <li>
        <FaCheckSquare className="service_list-icon" />
        <p>I specialize in building reliable web applications with a focus on backend development using technologies such as React, Django, Laravel, and MySQL.</p>
      </li>
      <li>
        <FaCheckSquare className="service_list-icon" />
        <p>My expertise extends to creating Ecommerce Applications, ensuring seamless online shopping experiences for users.</p>
      </li>
      <li>
        <FaCheckSquare className="service_list-icon" />
        <p>I excel in quality assurance (QA) and testing, ensuring the security and performance of web applications.</p>
      </li>
          </ul>
        </article>
        <article className="services">
          <div className="service_head">
            <h3>UI/UX Design</h3>
          </div>
          <ul className="service_list">
            <li>
              <FaCheckSquare className="service_list-icon" />
              <p> I improve user interaction  and customer satisfaction</p>
            </li>
            <li>
              <FaCheckSquare className="service_list-icon" />
              <p>I help to boost business growth by improving application runtime speed</p>
            </li>
          </ul>
        </article>       
      </div>
    </section>
  );
};

export default Services;
